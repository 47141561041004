/**
 * Colors
 */
/**
 * Fonts
 */
@import url(http://fonts.googleapis.com/css?family=Open+Sans);
@font-face {
  font-family: 'AlterWebHeadings';
  src: url("../../fonts/Museo300-Regular-webfont.eot");
  src: url("../../fonts/Museo300-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("../../fonts/Museo300-Regular-webfont.woff") format("woff"), url("../../fonts/Museo300-Regular-webfont.ttf") format("truetype"), url("../../fonts/Museo300-Regular-webfont.svg#Museo300Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

/**
 * Default text styling
 */
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 100%;
  color: #303030; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'AlterWebHeadings', arial; }

h1 {
  font-size: 2.25em;
  margin-bottom: 0.4em; }

h2 {
  font-size: 1.5em;
  margin-bottom: 0.6em; }

h3 {
  font-size: 1.3125em;
  margin-bottom: 0.7em; }

h4 {
  font-size: 1.125em;
  margin-bottom: 0.8em; }

h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em; }

h6 {
  font-size: 1em;
  font-style: italic;
  margin-bottom: 1em; }

p {
  margin: 1em 0px; }

a {
  color: #ff8a00;
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

h1 a {
  color: #303030;
  text-decoration: none; }

h1 a:hover {
  text-decoration: underline; }

h2 a {
  color: #303030;
  text-decoration: none; }

h2 a:hover {
  text-decoration: underline; }

h3 a {
  color: #303030;
  text-decoration: none; }

h3 a:hover {
  text-decoration: underline; }

h4 a {
  color: #303030;
  text-decoration: none; }

h4 a:hover {
  text-decoration: underline; }

h5 a {
  color: #303030;
  text-decoration: none; }

h5 a:hover {
  text-decoration: underline; }

h6 a {
  color: #303030;
  text-decoration: none; }

h6 a:hover {
  text-decoration: underline; }

strong, b {
  font-weight: 600; }

i {
  font-style: italic; }

img {
  height: auto;
  max-width: 100%; }

/* Helpers */
div.clear {
  clear: both; }

.middle-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

/**
 * Default layout
 */
html {
  background: #e9e9e9; }

#page-container {
  background: #ffffff;
  padding: 0;
  max-width: 37em;
  margin: 0 auto; }

.main-content {
  max-width: 33em;
  padding: 2em; }

header#page-header {
  padding-top: 1em;
  width: 100%;
  float: left;
  clear: both; }

header#page-header #logo {
  float: left;
  width: 25em;
  padding-top: 2em;
  padding-bottom: 2.7em;
  padding-left: 2em;
  padding-right: 2em; }

header#page-header #logo img {
  float: left;
  margin-right: 1.5em;
  width: 5.5em; }

header#page-header #logo .site-name {
  font-family: 'AlterWebHeadings', arial;
  font-size: 3.375em;
  font-weight: normal;
  float: left;
  clear: right;
  padding-top: 0.03em; }

header#page-header #logo .site-slogan {
  font-size: 1.125em;
  font-weight: normal;
  float: left;
  clear: right;
  margin-top: -0.125em; }

header#page-header .search {
  float: right;
  padding: 1.75em;
  clear: right;
  margin-top: 1.5em; }

.search form input {
  border: 1px solid #e7e7e7;
  height: 2.4em;
  float: left;
  max-width: 15em;
  padding: 0.2em 0.75em;
  border-radius: 0; }

.search form input[type=submit] {
  border-radius: 0;
  background: #f7f7f7;
  border: 1px solid #e7e7e7;
  border-left: none; }

.search form input[type=submit]:hover {
  border-radius: 0;
  background: #e7e7e7;
  border: 1px solid #e7e7e7;
  border-left: none; }

article ol {
  list-style-type: decimal;
  margin: 1em 0px;
  margin-left: 2em; }

article ol.codeblock {
  list-style-type: decimal-leading-zero;
  background: #E6E6E6;
  margin-left: 2em;
  padding-left: 40px;
  border: 1px solid #D6D6D6; }

article ol.codeblock li {
  background: #F6F6F6;
  padding-left: 15px;
  border-left: 1px solid #A6A6A6;
  /* wrap long text and urls */
  white-space: pre;
  /* CSS 2.0 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3.0 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: -moz-pre-wrap;
  /* Mozilla */
  word-wrap: break-word;
  /* IE 5+ */ }

article ol.shellblock {
  list-style-type: none;
  margin: 0;
  padding: 0.2em;
  background: #141414;
  color: #ffffff; }

article ol.shellblock li {
  padding-left: 1.5em;
  /* wrap long text and urls */
  white-space: pre;
  /* CSS 2.0 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3.0 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: -moz-pre-wrap;
  /* Mozilla */
  word-wrap: break-word;
  /* IE 5+ */ }

article ol.shellblock li:before {
  content: ">";
  margin-left: -1em;
  position: absolute; }

article a.download img {
  width: 2.5em;
  margin: 0; }

article a.download {
  float: right; }

/**
 * Default form formatting
 */
form label {
  display: block;
  float: left;
  padding-top: 0.3125em;
  min-width: 10em; }

form .form-input-group {
  clear: both;
  float: left;
  padding-bottom: 0.625em;
  overflow: hidden; }

form .form-input-group p {
  margin: 0.3em 0 0 11.2em;
  font-size: 0.9em;
  color: #777; }

form .form-checkbox-group input {
  margin-left: 10.2em;
  float: left; }

form .form-checkbox-group p, form .form-checkbox-group label {
  font-size: 0.9em;
  float: left;
  width: 23em;
  margin: 0.3em 0 1em 0.4em;
  padding: 0; }

form .form-url-group {
  clear: both;
  float: left;
  padding-bottom: 0.625em;
  overflow: hidden; }

form select {
  border: #a4a4a4 1px solid;
  background: #FFFFFF;
  padding: 0.3125em;
  margin: 0px;
  width: 22em;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

form input {
  border: #a4a4a4 1px solid;
  background: #FFFFFF;
  padding: 0.3125em;
  width: 22em;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

form textarea {
  border: #a4a4a4 1px solid;
  background: #FFFFFF;
  padding: 0.3125em;
  width: 22em;
  resize: vertical;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box; }

form input[type=checkbox] {
  width: auto;
  margin-top: 0.6em; }

form input[type=submit] {
  width: auto;
  display: block;
  color: #303030;
  padding: 0.5em 1em;
  border: solid 1px #a4a4a4;
  background: #e5e5e5;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #e5e5e5 0%, #d1d1d1 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#d1d1d1',GradientType=0 );
  /* IE6-9 */
  border-radius: 0.1875em;
  text-align: center; }

form .form-input-group input[type=submit] {
  margin-left: 10em; }

form input[type=submit]:hover {
  text-decoration: none;
  background: #d1d1d1;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #d1d1d1 0%, #e5e5e5 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d1', endColorstr='#e5e5e5',GradientType=0 );
  /* IE6-9 */ }

form input.warning[type=submit] {
  color: #faddde;
  border: solid 1px #980c10;
  background: #ed1c24;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #ed1c24 0%, #aa1317 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ed1c24', endColorstr='#aa1317',GradientType=0 );
  /* IE6-9 */ }

form input.warning[type=submit]:hover {
  background: #aa1317;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #aa1317 0%, #ed1c24 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#aa1317', endColorstr='#ed1c24',GradientType=0 );
  /* IE6-9 */ }

form .form-error {
  color: #fc2314;
  margin: 0.5em 0 0.5em 10em;
  clear: left;
  display: block; }

.button {
  display: block;
  color: #303030;
  float: left;
  padding: 0.5em;
  border: solid 1px #a4a4a4;
  background: #e5e5e5;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #e5e5e5 0%, #d1d1d1 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#d1d1d1',GradientType=0 );
  /* IE6-9 */
  border-radius: 3px;
  text-align: center; }

.button:hover {
  text-decoration: none;
  background: #d1d1d1;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #d1d1d1 0%, #e5e5e5 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d1', endColorstr='#e5e5e5',GradientType=0 );
  /* IE6-9 */ }

.button.warning {
  color: #faddde;
  border: solid 1px #980c10;
  background: #ed1c24;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #ed1c24 0%, #aa1317 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ed1c24', endColorstr='#aa1317',GradientType=0 );
  /* IE6-9 */ }

.button.warning:hover {
  background: #aa1317;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #aa1317 0%, #ed1c24 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#aa1317', endColorstr='#ed1c24',GradientType=0 );
  /* IE6-9 */ }

form .form-confirm-group .button {
  padding: 0.5em 1em;
  margin-right: 1em; }

a.button.first {
  border-radius: 0px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

a.button.center {
  border-left: none;
  border-radius: 0px; }

a.button.last {
  border-left: none;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px; }

.button.important {
  color: #FFFFFF;
  background: #ffae00;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #ffae00 0%, #ff8a00 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffae00', endColorstr='#ff8a00',GradientType=0 );
  /* IE6-9 */ }

.button.important:hover {
  color: #FFFFFF;
  background: #ff8a00;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #ff8a00 0%, #ffae00 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8a00', endColorstr='#ffae00',GradientType=0 );
  /* IE6-9 */ }

span.from {
  color: #FF0000;
  font-size: 80%;
  text-decoration: line-through; }

.extra {
  font-size: 0.8em;
  padding-top: 0.8em;
  border-top: #e7e7e7 1px solid; }

div.msg {
  padding: 1em;
  margin-bottom: 1em; }

div.msg.msg-success {
  border: solid 1px #4F8A10;
  background: #DFF2BF; }

div.msg.msg-error {
  border: solid 1px #D8000C;
  background: #FFBABA; }

div.msg.msg-warning {
  border: solid 1px #9F6000;
  background: #FEEFB3; }

div.msg.msg-info {
  border: solid 1px #00529B;
  background: #BDE5F8; }

header#page-header a {
  color: #303030; }

.toolbar-container {
  text-align: center;
  float: left;
  width: 100%;
  border-top: 1px solid #e7e7e7; }

.toolbar {
  display: inline-block;
  padding-top: 2em; }

.toolbar .pages span {
  margin-right: 0.6em; }

.toolbar .pagination li {
  float: left; }

.toolbar .pagination li a {
  margin-right: 0.3em;
  color: #303030;
  padding: 0.5em;
  min-width: 1em;
  display: block;
  text-align: center;
  border: solid 1px #a4a4a4;
  background: #e5e5e5;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #e5e5e5 0%, #d1d1d1 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#d1d1d1',GradientType=0 );
  /* IE6-9 */
  border-radius: 3px; }

.toolbar .pagination li span {
  cursor: default;
  margin-right: 0.3em;
  color: #909090;
  padding: 0.5em;
  min-width: 1em;
  display: block;
  text-align: center;
  text-decoration: none;
  border: solid 1px #a4a4a4;
  background: #d1d1d1;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #d1d1d1 0%, #e5e5e5 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d1', endColorstr='#e5e5e5',GradientType=0 );
  /* IE6-9 */
  border-radius: 3px; }

.toolbar .pagination li:last-child a, .toolbar .pagination li:last-child span {
  margin-right: 0; }

.toolbar .pagination li a:hover {
  text-decoration: none;
  cursor: pointer;
  cursor: hand;
  background: #d1d1d1;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #d1d1d1 0%, #e5e5e5 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1d1d1', endColorstr='#e5e5e5',GradientType=0 );
  /* IE6-9 */ }

.toolbar .pagination li.active span {
  color: #FFFFFF;
  background: #ffae00;
  /* Old browsers */
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #ffae00 0%, #ff8a00 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffae00', endColorstr='#ff8a00',GradientType=0 );
  /* IE6-9 */ }

article img.article-img {
  float: left;
  width: 20%;
  margin: 0 5% 0 0; }

article div.footer {
  width: 75%;
  float: right;
  margin-top: 0px; }

article div.footer {
  width: 100%;
  float: left;
  margin-top: 0.625em;
  border-top: solid 1px #e7e7e7;
  padding-top: 0.625em; }

article p.english {
  background: url("../../images/english.png") no-repeat #fcfcfc;
  background-position: 15px 15px;
  padding: 1em;
  padding-left: 66px;
  border: 1px solid #e7e7e7; }

article p.dutch {
  background: url("../../images/dutch.png") no-repeat #fcfcfc;
  background-position: 15px 15px;
  padding: 1em;
  padding-left: 66px;
  border: 1px solid #e7e7e7; }

article ul.blog-overview {
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-top: 30px; }

article ul.blog-overview li {
  float: left;
  border-bottom: solid 1px #e7e7e7;
  padding-bottom: 20px;
  margin-bottom: 20px; }

article ul.blog-overview li:last-child {
  border: none;
  margin: 0; }

article ul.blog-overview li div.footer {
  width: 75%;
  float: right;
  border: none;
  margin-top: 0; }

article ul.blog-overview li p {
  width: 75%;
  float: right;
  margin: 0; }

article div.footer span {
  float: left; }

article div.footer img {
  float: left;
  margin: 0 0.5em 0 0; }

article div.footer img.calendar {
  height: 1.4em; }

article div.footer img.author {
  height: 1.5em;
  margin-top: -2px;
  margin-left: 1em; }

article div.footer span.author {
  margin-right: 1em; }

article div.footer div.tags {
  float: left;
  clear: both;
  margin-top: 0.6em; }

article div.footer img.tag {
  height: 1.3em; }

article div.footer ul.tags {
  float: left;
  list-style-type: none;
  padding: 0;
  margin: 0; }

article div.footer ul.tags li {
  float: left;
  margin-left: 0.5em; }

article div.footer ul.tags li.first {
  margin-left: 0; }

article div.footer ul.tags li:after {
  content: ","; }

article div.footer ul.tags li.last:after {
  content: ""; }

article ul.magento-msg-overview {
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-top: 30px; }

article ul.magento-msg-overview li {
  float: left;
  border-bottom: solid 1px #e7e7e7;
  padding-bottom: 20px;
  margin-bottom: 20px; }

article ul.magento-msg-overview li:last-child {
  border: none;
  margin: 0; }

article ul.magento-msg-overview li div.footer {
  border: none;
  margin-top: 0; }

article ul.magento-msg-overview li p {
  margin: 0; }

article q {
  background: url("../../images/quote-close-black.svg") no-repeat right 1em bottom 1em, url("../../images/quote-black.svg") no-repeat left 1em top 1em #F6F6F6;
  background-size: 0.7em;
  display: block;
  border: 1px solid #d7d7d7;
  padding: 1em 2em;
  margin: 1em 0px; }

.main-content aside {
  padding: 1em;
  background: #F6F6F6;
  border: 1px solid #e7e7e7;
  margin-bottom: 1.5em; }

.main-content aside p:last-child {
  margin-bottom: 0; }

.block {
  border: solid 1px #e7e7e7;
  float: left;
  margin-bottom: 2em;
  width: 16em; }

.block .block-footer {
  width: 14em;
  padding: 0.5em 0;
  margin: 0 1em;
  border-top: 1px solid #e7e7e7; }

.block > div {
  padding: 1em;
  width: 100%;
  float: left;
  box-sizing: border-box;
  overflow: auto; }

.block.double, table.double {
  width: 100%; }

.block.double > div {
  width: 100%; }

.block.double > div.block-footer {
  width: 93%; }

.block.full-width {
  width: 100%; }

.block.full-width > div, table.full-width {
  width: 100%; }

.block.full-width > div.block-footer {
  width: 93%; }

.block div.block-title {
  border-bottom: solid 1px #e7e7e7;
  background: #f7f7f7; }

.block div.block-title h6,
.block div.block-title h5,
.block div.block-title h4,
.block div.block-title h3 {
  padding: 0;
  margin: 0;
  font-size: 1.2em;
  font-weight: normal;
  font-style: normal; }

.block .block-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.block .block-content p:last-child,
.block .block-content h1:last-child,
.block .block-content h2:last-child,
.block .block-content h3:last-child,
.block .block-content h4:last-child,
.block .block-content h5:last-child,
.block .block-content h6:last-child {
  margin-bottom: 0; }

.block .block-content form input {
  width: 13em;
  margin-bottom: 0.8em; }

.block .block-content form input:last-child {
  margin-bottom: 0; }

.block .block-content form select {
  width: 13em;
  margin-bottom: 0.8em; }

.block .block-content form select:last-child {
  margin-bottom: 0; }

.block .block-content form label {
  padding: 0;
  float: none; }

.block .block-content form input[type=submit] {
  margin-left: 0;
  padding: 0.5em 0.3125em; }

.block.icon-menu .block-content ul {
  margin-top: -1.5em; }

.block.icon-menu .block-content ul li {
  width: 100%;
  margin-top: 1.5em; }

.block.icon-menu .block-content ul li img {
  width: 2em;
  float: left;
  margin-right: 1em;
  margin-top: -0.2em; }

.block.customer-info .block-content span.label {
  min-width: 4em;
  display: block;
  float: left; }

.block .block-content img.icon {
  padding: 0;
  margin: 0 0 -0.1em 0;
  width: 1.625em;
  height: 1em; }

table.grid img.icon {
  padding: 0;
  margin: 0 0 -0.1em 0;
  width: auto;
  height: 1em; }

.block.uptimemonitor .block-content form label {
  float: left;
  padding-top: 0.3125em; }

.block.uptimemonitor .block-content form input {
  margin: 0;
  width: 32.8em; }

.block.uptimemonitor .block-content form .form-input-group p {
  max-width: 36.2em; }

.block.uptimemonitor .block-content form .form-url-group input {
  width: 9em;
  margin-right: 0.2em; }

.block.uptimemonitor .block-content form .form-url-group select {
  width: 8em;
  margin: 0;
  margin-right: 0.2em; }

.block.uptimemonitor .block-content form .form-url-group select.protocol {
  width: 5.1em; }

.block.uptimemonitor .block-content form .form-url-group span {
  margin-right: 0.2em; }

.block.uptimemonitor .block-content form .form-input-group input[type=submit] {
  margin-left: 10em;
  width: auto;
  padding: 0.5em 1em; }

.block.uptimemonitor .block-content form .form-input-group.remove {
  width: 100%; }

.block.uptimemonitor .block-content form .button {
  padding-left: 1em;
  padding-right: 1em; }

aside.sidebar .tagcloud .block-content {
  text-align: center; }

aside.sidebar .tagcloud .tag {
  margin: 0.2em; }

aside.sidebar .tagcloud .smallest {
  font-size: x-small; }

aside.sidebar .tagcloud .small {
  font-size: small; }

aside.sidebar .tagcloud .medium {
  font-size: medium; }

aside.sidebar .tagcloud .large {
  font-size: large; }

aside.sidebar .tagcloud .largest {
  font-size: larger; }

aside.sidebar .block.blog.posts div.block-content ul {
  padding: 0;
  list-style: none; }

aside.sidebar .block.blog.posts div.block-content ul li {
  padding: 0;
  border: none;
  margin-bottom: 1em;
  min-height: 4em;
  clear: both; }

aside.sidebar .block.blog.posts div.block-content ul li:last-child {
  margin-bottom: 0;
  min-height: 4em; }

aside.sidebar .block.blog.posts div.block-content img {
  float: left;
  clear: left;
  margin: 0;
  margin-right: 5%;
  width: 30%; }

aside.sidebar .block.blog.posts div.block-content img.alterweb-logo {
  width: 20%;
  margin-left: 2%;
  margin-right: 8%; }

aside.sidebar #map_canvas {
  width: 100%;
  height: 12.5em;
  margin-bottom: 1em; }

.main-content img {
  margin: 0; }

.main-content ul {
  list-style-type: disc;
  margin: 1em 0;
  padding-left: 2em; }

.main-content ul.checks {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5em; }

ul.checks li {
  background: url("../../images/check.svg") no-repeat left top;
  background-size: 1.25em;
  margin: 0.625em 0 0.625em 0.625em;
  padding-left: 2em; }

.no-svg ul.checks li {
  background: url("../../images/check.png") no-repeat left top;
  background-size: 1.25em; }

div#content-header div#content-header-content ul.checks li {
  background: url("../../images/banner-check.svg") no-repeat left top; }

.no-svg div#content-header div#content-header-content ul.checks li {
  background: url("../../images/banner-check.png") no-repeat left top; }

.main-content ul.pagination {
  list-style-type: none;
  padding: 0;
  margin: 0; }

/** Search */
#body-search {
  width: 100%;
  clear: both;
  height: 2.5em;
  margin-bottom: 2em; }

#body-search input[type=text] {
  width: 28em;
  max-width: none; }

#body-search input[type=submit] {
  width: 5em; }

/** Sitemap */
article ul.sitemap {
  list-style-type: none;
  margin: 0px;
  padding: 0px; }

article ul.sitemap ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  padding-left: 2em; }

article ul.sitemap li span.admin-links {
  margin-left: 2em;
  float: right; }

/* Portfolio */
.main-content ul.portfolio-overview {
  list-style-type: none;
  padding: 0; }

.portfolio-overview li {
  float: left;
  margin-bottom: 2em; }

.portfolio-overview li div.profile-container {
  border: 1px solid #e7e7e7; }

.portfolio-overview li div.logo-container {
  border-bottom: solid 1px #e7e7e7;
  height: 9.375em;
  text-align: center; }

.portfolio-overview li div.logo-container img {
  vertical-align: middle;
  width: 70%;
  float: none;
  margin: 0; }

.portfolio-overview li .info {
  padding: 1em;
  min-height: 4.3em; }

.portfolio-overview li p {
  margin: 0; }

.portfolio-overview li h3 {
  margin: 0; }

/* Images */
img.logo-kontikireizen {
  max-width: 272px; }

img.logo-2hills {
  max-width: 260px; }

img.logo-shenza {
  max-width: 202px; }

img.logo-nafvsm {
  max-width: 160px; }

img.logo-safety-green {
  max-width: 188px; }

img.logo-inktboer {
  max-width: 185px; }

img.logo-oqium {
  max-width: 157px; }

/* Contact page */
body.contact #map_canvas {
  float: left;
  margin: 1em 0;
  width: 100%;
  height: 12.5em; }

/* Website pakketten */
body.website-pakketten .tekst .extra, body.statistieken-pakketten .tekst .extra {
  padding-bottom: 0.8em;
  border-bottom: #e7e7e7 1px solid;
  margin-bottom: 2em;
  margin-top: 2em; }

table.compare {
  width: 100%; }

table.compare td {
  border: #e7e7e7 1px solid;
  padding: 1.5%; }

table.compare td {
  text-align: center; }

table.compare td.title-description {
  text-align: left; }

table.compare td img {
  float: none;
  width: auto;
  height: 2em; }

table.compare tr.even td {
  background: #fcfcfc; }

table.compare tr.pakketten td {
  border: #ffaf50 1px solid;
  background: #ffe9cf; }

table.compare tr.pakketten td.title-description {
  border: none;
  background: none; }

table.compare td p {
  font-size: 0.9em;
  display: none; }

table.compare td h3, article.default-article table.compare td h4 {
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
  font-size: 100%; }

/* Privacy page */
body.privacy-cookies .browser {
  border-top: 1px solid #e7e7e7;
  margin-top: 2em;
  padding-top: 2em; }

body.privacy-cookies .browser-logo {
  width: 20%;
  float: left;
  margin: 0.5em 2em 2em 0; }

.sidebar .block.privacy .block-content h6 {
  margin-bottom: 0.5em;
  font-style: normal; }

.sidebar .block.privacy .block-content p.setting {
  font-size: 0.8em;
  font-weight: bold;
  font-style: italic;
  margin-top: 0; }

.sidebar .block.privacy .block-content p.extra {
  margin-bottom: 0; }

/**
 * Promotiemiddelen
 */
body.beeldmateriaal-promotiemiddelen .main-content .product-container h4 {
  min-height: 2.2em; }

body.beeldmateriaal-promotiemiddelen .main-content img {
  max-height: 15em; }

body.beeldmateriaal-promotiemiddelen .main-content img.product {
  max-width: 10em;
  max-height: 10em; }

/**
 * bits-bytes-converter
 */
.bits-bytes-converter form {
  float: left;
  margin-bottom: 2em; }

.bits-bytes-converter input {
  float: left; }

.bits-bytes-converter input[type=submit] {
  margin-left: 0.5em; }

.bits-bytes-converter select {
  float: left;
  width: auto;
  margin-left: 0.5em; }

.bits-bytes-converter select#soort {
  margin-left: 0; }

.bits-bytes-converter table td, .bits-bytes-converter table th {
  border: 1px solid #e7e7e7;
  padding: 10px; }

.bits-bytes-converter table#bitbyte {
  margin-bottom: 20px;
  margin-top: 20px; }

/**
 * Referral spam toool
 */
#referral-spam-tool {
  width: 90%;
  background: #F6F6F6;
  border: 1px solid #d7d7d7;
  float: left;
  padding: 20px; }

#referral-spam-tool h4 {
  display: block;
  width: 100%;
  background: #ff8a00;
  border: 1px solid #ff6600;
  border-bottom: none;
  color: #fff;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: -21px;
  margin-top: -21px;
  margin-bottom: 20px; }

#referral-spam-tool div#progress {
  max-height: 9.8em;
  overflow-y: scroll; }

#referral-spam-tool div.form-group {
  float: left;
  width: 100%; }

#referral-spam-tool label {
  display: block;
  width: 50%;
  margin-right: 5%;
  float: left; }

#referral-spam-tool select {
  float: left;
  width: 45%;
  margin-bottom: 20px; }

#referral-spam-tool button {
  padding: 10px;
  float: left; }

/**
 * Product overview
 */
.main-content div.product {
  width: 28%;
  float: left;
  margin-right: 7.33%;
  margin-top: 2em; }

.main-content div.product.last {
  margin-right: 0; }

.main-content div.product.website,
.main-content div.product.webshop,
.main-content div.product.security,
.main-content div.product.zoekmachine-optimalisatie,
.main-content div.product.maatwerk {
  margin-top: 0px; }

.main-content div.product p {
  min-height: 9.2em; }

.main-content div.product img {
  display: block;
  margin-top: 0;
  margin-left: 15%;
  width: 55%;
  margin-bottom: 10px; }

.main-content div.product ul {
  margin-top: 1.5em;
  margin-bottom: 1.5em; }

.main-content div.product div.more {
  display: block;
  float: left;
  width: 100%; }

.main-content div.product div.more a.button.first {
  width: 32%; }

.main-content div.product div.more a.button.last {
  width: 58%;
  margin-right: -23px; }

body.front .product {
  margin-bottom: 2em; }

/**
 * Customerpanel
 */
#customerpanelmenu {
  float: left;
  width: 100%;
  margin-bottom: 2em; }

#customerpanelmenu ul {
  margin: 0;
  list-style: none;
  padding: 0; }

#customerpanelmenu ul li {
  padding: 1em 1em 1em 3.5em;
  border-bottom: 1px solid #e7e7e7; }

#customerpanelmenu ul li.dashboard {
  background: url("../../images/icon-dashboard.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.dashboard:hover, #customerpanelmenu ul li.dashboard.active {
  background: url("../../images/icon-dashboard-active.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.mail {
  background: url("../../images/icon-mail.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.mail:hover, #customerpanelmenu ul li.mail.active {
  background: url("../../images/icon-mail-active.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.tools {
  background: url("../../images/icon-tools.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.tools:hover, #customerpanelmenu ul li.tools.active {
  background: url("../../images/icon-tools-active.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.uptimemonitor {
  background: url("../../images/icon-timer.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.uptimemonitor:hover, #customerpanelmenu ul li.uptimemonitor.active {
  background: url("../../images/icon-timer-active.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.hosting {
  background: url("../../images/icon-hosting.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.hosting:hover, #customerpanelmenu ul li.hosting.active {
  background: url("../../images/icon-hosting-active.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.domainnames {
  background: url("../../images/icon-domainname.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.domainnames:hover, #customerpanelmenu ul li.domainnames.active {
  background: url("../../images/icon-domainname-active.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.messages {
  background: url("../../images/icon-message.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.messages:hover, #customerpanelmenu ul li.messages.active {
  background: url("../../images/icon-message-active.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.database {
  background: url("../../images/icon-database.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li.database:hover, #customerpanelmenu ul li.database.active {
  background: url("../../images/icon-database-active.svg");
  background-repeat: no-repeat;
  background-position: 1em 0.95em;
  background-size: 1.5em; }

#customerpanelmenu ul li:last-child {
  border-bottom: none; }

#customerpanel-content {
  float: left;
  width: 100%; }

#customerpanel-content table.grid {
  margin-bottom: 2em;
  float: left; }

#customerpanel-content table.grid tr td,
#customerpanel-content table.grid tr th {
  padding: 0.5em 1em;
  text-align: left;
  border: solid 1px #e7e7e7;
  vertical-align: top; }

#customerpanel-content table.grid tr th {
  background: #f7f7f7;
  padding: 1em 1em;
  font-family: 'AlterWebHeadings', arial;
  font-size: 1.1em; }

#customerpanel-content table.grid tr td img {
  margin: 0; }

#customerpanel-content table.grid tr td img.icon {
  margin-bottom: -0.1em; }

#customerpanel-content table.grid.uptimemonitors tr td.alertcontacts,
#customerpanel-content table.grid.uptimemonitors tr th.alertcontacts,
#customerpanel-content table.grid.uptimemonitors tr td.status,
#customerpanel-content table.grid.uptimemonitors tr th.status,
#customerpanel-content table.grid.uptimemonitors tr td.uptime,
#customerpanel-content table.grid.uptimemonitors tr th.uptime,
#customerpanel-content table.grid.hosting tr td.status,
#customerpanel-content table.grid.hosting tr th.status,
#customerpanel-content table.grid.domainnames tr td.status,
#customerpanel-content table.grid.domainnames tr th.status,
#customerpanel-content table.grid.domainnames tr td.ssl,
#customerpanel-content table.grid.domainnames tr th.ssl,
#customerpanel-content table.grid tr td.expire-date,
#customerpanel-content table.grid tr th.expire-date {
  text-align: center; }

#customerpanel-content table.grid tr td ul {
  margin: 0;
  list-style: none;
  padding: 0; }

#customerpanel-content #referral-spam-tool {
  width: 34.1em;
  padding: 0;
  background: none;
  border: solid 1px #e7e7e7; }

#customerpanel-content #referral-spam-tool .button {
  width: 30.9em; }

#customerpanel-content #referral-spam-tool h4 {
  padding: 0;
  margin: 0;
  border: none;
  color: #303030;
  background: none; }

#customerpanel-content .block.hosting .block-content .icon {
  float: left;
  margin: 0.2em 0.2em 0 0;
  height: 1em; }

#customerpanel-content .block .block-content p:first-child {
  margin-top: 0; }

#customerpanel-content table.grid.domainnames tr td.ssl,
#customerpanel-content table.grid.domainnames tr th.ssl,
#customerpanel-content table.grid.hosting tr td.domainnames,
#customerpanel-content table.grid.hosting tr th.domainnames,
#customerpanel-content table.grid.domainnames tr td.account,
#customerpanel-content table.grid.domainnames tr th.account,
#customerpanel-content table.grid.domainnames span.big-only {
  display: none; }

#customerpanel-content div.block.hosting .block-content > table {
  width: 100%;
  margin-bottom: 1em; }

#customerpanel-content div.block.hosting .block-content table thead td {
  text-align: right;
  font-weight: bold; }

#customerpanel-content div.block.hosting table td {
  padding: 0.2em; }

#customerpanel-content div.block.hosting table td.value {
  text-align: right; }

#customerpanel-content div.block.hosting .hosting-usage-space {
  margin-bottom: 1em; }

#customerpanel-content div.block.hosting .hosting-usage-space div.info-container {
  display: none;
  border: solid 1px #e7e7e7;
  padding: 0.8em;
  margin-top: 0.5em;
  position: absolute;
  background: #ffffff; }

#customerpanel-content div.block.hosting .hosting-usage-space:hover div.info-container {
  display: block; }

#customerpanel-content div.block.hosting .hosting-usage-space table tr.header td {
  padding-top: 1em; }

#customerpanel-content div.block.hosting .hosting-usage-space table tr.header:first-child td {
  padding-top: 0; }

#customerpanel-content div.block.hosting .hosting-usage-space table td {
  padding: 0.2em; }

#customerpanel-content div.block.hosting .hosting-usage-space table td.size {
  padding-left: 1em;
  text-align: right; }

div.bigbanner {
  margin-bottom: 1em; }

div.bigbanner div.bx-viewport {
  border: solid 1px #e7e7e7; }

div.bigbanner a.full-screen-slide {
  display: block; }

/* Compare products */
div.compare-products {
  margin-bottom: 3em;
  float: left; }

div.compare-products .product {
  margin: 3em -1px 0 0;
  width: 50%; }

div.compare-products .product .header {
  padding: 1.5em 1em;
  background: #f7f7f7;
  text-align: center;
  border: 1px solid #c4c4c4;
  border-bottom: none; }

div.compare-products .product.last .header {
  border: 1px solid #c4c4c4;
  border-bottom: none; }

div.compare-products .product .header h4 {
  font-size: 1.5em; }

div.compare-products .product ul {
  border: solid 1px #c4c4c4;
  margin: 0;
  list-style: none;
  padding: 0; }

div.compare-products .product.last ul {
  border: solid 1px #c4c4c4; }

div.compare-products .product ul li {
  padding: 1em;
  border-bottom: 1px solid #c4c4c4;
  margin: 0; }

div.compare-products .product ul li:nth-child(even) {
  background: #fcfcfc; }

div.compare-products .product ul li.last {
  border-bottom: none; }

div.compare-products .product a.order {
  background: url(../../images/cart.svg) 5.4em 1.1em no-repeat, #e5e5e5;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: url(../../images/cart.svg) 5.4em 1.1em no-repeat, linear-gradient(to bottom, #e5e5e5 0%, #d1d1d1 100%);
  /* W3C */
  border: 1px solid #c4c4c4;
  border-top: none;
  color: #303030;
  padding: 1em 1em 1em 7.5em;
  display: block; }

div.compare-products .product.last a.order {
  border: 1px solid #c4c4c4;
  border-top: none; }

div.compare-products .product a.order:hover {
  background: url(../../images/cart.svg) 5.4em 1.1em no-repeat, #d1d1d1;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: url(../../images/cart.svg) 5.4em 1.1em no-repeat, linear-gradient(to bottom, #d1d1d1 0%, #e5e5e5 100%);
  /* W3C */
  text-decoration: none; }

div.compare-products .product.highlight {
  margin: 1.5em -1px 0 0;
  position: relative;
  z-index: 9; }

div.compare-products .product.highlight .header {
  background: #ff8a00;
  color: #ffffff;
  border: 1px solid #ff6e00;
  border-bottom: none; }

div.compare-products .product.highlight ul {
  border: solid 1px #ff6e00; }

div.compare-products .product.highlight ul li {
  border-bottom: 1px solid #ff6e00;
  background: #ffffff; }

div.compare-products .product.highlight ul li.last {
  border-bottom: none; }

div.compare-products .product.highlight ul li:nth-child(even) {
  background: #fff1e2; }

div.compare-products .product.highlight a.order {
  background: url("../../images/cart-white.svg") no-repeat, #ffae00;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: url("../../images/cart-white.svg") 5.4em 1.1em no-repeat, linear-gradient(to bottom, #ffae00 0%, #ff8a00 100%);
  border: 1px solid #ff6e00;
  border-top: none;
  color: #ffffff; }

div.compare-products .product.highlight a.order:hover {
  background: url("../../images/cart-white.svg") no-repeat, #ff8a00;
  /* FF3.6+ */
  /* Chrome,Safari4+ */
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: url("../../images/cart-white.svg") 5.4em 1.1em no-repeat, linear-gradient(to bottom, #ff8a00 0%, #ffae00 100%);
  text-decoration: none; }

/* SMALL */
@media (max-width: 56.9em) {
  /** Search */
  #body-search {
    width: 100%;
    clear: both;
    height: 2.5em;
    margin-bottom: 2em; }
  #body-search input[type=text] {
    width: 100%;
    max-width: none; }
  #body-search input[type=submit] {
    margin: 1em 0;
    float: right;
    border: 1px solid #e7e7e7;
    width: 5em; }
  header#page-header #top-search {
    display: none; }
  .mobile-hidden {
    display: none; }
  header#page-header {
    padding: 0; }
  header#page-header.f-nav {
    margin-bottom: 5.75em; }
  header#page-header #logo {
    float: none;
    margin: 0 auto;
    width: 22.9em;
    padding: 1em 0; }
  nav#main-nav {
    display: none; }
  nav#top-navigation {
    display: none; }
  nav.breadcrumbs {
    display: none; }
  div.mobile-menu {
    text-align: center;
    background: #ff8a00;
    width: 100%;
    float: left;
    clear: both;
    margin-top: 1.25em;
    margin-bottom: 1.25em; }
  div.mobile-menu.f-nav {
    z-index: 9999;
    position: fixed;
    top: 0;
    margin: 0px;
    border-bottom: #ff6a00 1px solid;
    max-width: 37em; }
  div.mobile-menu a.site-name {
    font-family: 'AlterWebHeadings', arial;
    margin: 0.214285714em;
    margin-top: 0.428571429em;
    margin-right: 0.714285714em;
    font-size: 1.75em;
    display: inline-block;
    color: #FFFFFF; }
  header#page-header div.mobile-menu a.site-name {
    display: none;
    color: #FFFFFF; }
  header#page-header div.mobile-menu a.site-name:hover {
    text-decoration: none;
    color: #515151; }
  div.mobile-menu a.menu-button {
    float: right;
    margin: 0.625em;
    margin-right: 5%;
    display: block;
    width: 2em;
    height: 2em;
    background: url(../../images/menu-white.svg) no-repeat;
    background-size: 2em;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden; }
  .no-svg div.mobile-menu a.menu-button {
    background: url(../../images/menu-white.png) no-repeat;
    background-size: 2em; }
  div.mobile-menu a.menu-button:hover {
    background: url(../../images/menu-grey.svg) no-repeat;
    background-size: 2em; }
  .no-svg div.mobile-menu a.menu-button:hover {
    background: url(../../images/menu-grey.png) no-repeat;
    background-size: 2em; }
  div.mobile-menu a.phone-button {
    float: left;
    margin: 0.625em;
    margin-left: 5%;
    display: block;
    width: 2em;
    height: 2em;
    background: url(../../images/phone-white.svg) no-repeat;
    background-size: 2em;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden; }
  .no-svg div.mobile-menu a.phone-button {
    background: url(../../images/phone-white.png) no-repeat;
    background-size: 2em; }
  div.mobile-menu a.phone-button:hover {
    background: url(../../images/phone-grey.svg) no-repeat;
    background-size: 2em; }
  .no-svg div.mobile-menu a.phone-button:hover {
    background: url(../../images/phone-grey.png) no-repeat;
    background-size: 2em; }
  div.mobile-menu a.top-button {
    float: right;
    margin: 0.625em;
    margin-bottom: 0.75em;
    margin-right: 5%;
    display: none;
    width: 1.875em;
    height: 1.875em;
    background: url(../../images/arrow-up-white.svg) no-repeat;
    background-size: 1.875em;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden; }
  .no-svg div.mobile-menu a.top-button {
    background: url(../../images/arrow-up-white.png) no-repeat;
    background-size: 1.875em; }
  div.mobile-menu a.top-button:hover {
    background: url(../../images/arrow-up-grey.svg) no-repeat;
    background-size: 1.875em; }
  .no-svg div.mobile-menu a.top-button:hover {
    background: url(../../images/arrow-up-grey.png) no-repeat;
    background-size: 1.875em; }
  div#content-header {
    padding: 0 2em;
    float: left; }
  .main-content {
    padding-top: 1em;
    float: left;
    width: 100%;
    box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    max-width: 37em; }
  .sidebar {
    width: 100%;
    float: left;
    margin-bottom: 1em;
    margin-top: 1em; }
  .sidebar.second {
    margin-top: 2em; }
  .sidebar .sidebar-image {
    float: left;
    margin-left: 2em;
    margin-right: 2em;
    width: 15.4em; }
  aside.sidebar #map_canvas {
    width: 90%;
    margin-left: 5%; }
  .sidebar .sidebar-image:nth-child(2n+0) {
    margin-left: 0;
    margin-right: 0;
    clear: right; }
  .sidebar .block {
    margin-left: 2em;
    margin-right: 2em;
    width: 15.4em; }
  .sidebar .block:nth-child(2n+0) {
    margin-left: 0;
    margin-right: 0; }
  .sidebar .button {
    width: 15.4em;
    box-sizing: border-box; }
  /**
	 * FOOTER
	 */
  footer div#partner-logos {
    border-top: 1px solid #e7e7e7;
    padding-top: 1.25em;
    padding-bottom: 1.25em;
    float: left;
    width: 90%;
    clear: both;
    margin-top: 0;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center; }
  footer div#partner-logos img {
    height: 1.875em; }
  footer div#partner-logos img.first {
    float: left; }
  footer div#partner-logos img.last {
    float: right; }
  footer div#footer-menu {
    float: left;
    clear: both;
    width: 100%;
    text-align: center;
    height: 3.25em;
    background: #ff8a00; }
  footer div#footer-menu a {
    font-family: 'AlterWebHeadings', arial;
    margin-left: 2.5%;
    margin-top: 0.5em;
    margin-right: 2.5%;
    margin-bottom: 0.75em;
    font-size: 1.5em;
    display: inline-block;
    color: #FFFFFF; }
  footer div#footer-menu a:hover {
    text-decoration: none;
    color: #515151; }
  footer div.footer-links {
    text-align: center;
    float: left;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%; }
  footer div.footer-links h6 {
    display: none; }
  footer div.footer-links li a {
    border-bottom: 1px solid #e7e7e7;
    display: block;
    width: 100%;
    padding-top: 1.25em;
    padding-bottom: 1.25em;
    color: #303030;
    text-transform: uppercase;
    font-size: 1.125em; }
  footer div.footer-links li.active a span {
    background: url(../../images/sub-nav-arrow.svg) no-repeat left center;
    padding-left: 1.5em;
    padding-right: 1.5em;
    background-size: 1em; }
  .no-svg footer div.footer-links li.active a span {
    background: url(../../images/sub-nav-arrow.png) no-repeat left center;
    background-size: 1em; }
  footer div.footer-links span.copyright {
    float: right;
    margin-top: 2.5em;
    margin-bottom: 0.625em; }
  /* Portfolio */
  .portfolio-overview li {
    width: 47.5%;
    margin-right: 5%; }
  .portfolio-overview li:nth-child(2n+0) {
    margin-right: 0; }
  /* Contact page */
  body.contact .info-container {
    margin-top: 2em;
    clear: both; }
  /* Website pakketten */
  body.website-pakketten img.website-from {
    display: none; }
  /* Statistieken pakketten */
  body.statistieken-pakketten img.statistieken {
    display: none; }
  /**
	 * Product overview
	 */
  .main-content div.product {
    width: 100%;
    float: left;
    margin-right: 0;
    margin-top: 2em; }
  /**
	 * Customerpanel
	 */
  #customerpanel-content .single:nth-child(2n+1) {
    margin-left: 0.7em; }
  #customerpanel-content .block-container {
    margin-right: 0.7em;
    float: left;
    width: 16.125em; }
  #customerpanel-content #referral-spam-tool {
    width: 100%; }
  #customerpanel-content #referral-spam-tool .button {
    padding: 2%;
    width: 96%; }
  div.bigbanner a.full-screen-slide {
    width: 33em;
    height: 11.6em; }
  div.bigbanner a.full-screen-slide.banner-magento-hosting {
    background: url(../../images/hosting-banner-small.svg);
    background-size: 33em auto; }
  div.bigbanner a.full-screen-slide.banner-magento-security {
    background: url(../../images/security-banner-small.svg);
    background-size: 33em auto; }
  div.bigbanner a.full-screen-slide.banner-blog {
    background: url(../../images/tutorial-banner-small.svg);
    background-size: 33em auto; } }

/* EXTRA SMALL*/
@media (max-width: 37.9em) {
  form label {
    float: none; }
  form .form-input-group {
    float: none;
    padding-bottom: 0.625em; }
  form .form-input-group p {
    margin: 0.3em 0; }
  form .form-checkbox-group input {
    margin-left: 0;
    float: left; }
  form .form-checkbox-group p, form .form-checkbox-group label {
    font-size: 0.9em;
    float: left;
    width: 90%; }
  form select {
    width: 100%; }
  form input {
    width: 100%; }
  form textarea {
    width: 100%; }
  .sidebar {
    width: 88%;
    padding: 0 6%;
    float: left; }
  .sidebar.second {
    margin-top: 2em; }
  aside.sidebar .block {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  .sidebar .sidebar-image, .sidebar .button {
    margin: 0 auto;
    display: block;
    float: none; }
  /* Portfolio */
  .portfolio-overview li {
    width: 100%;
    margin-right: 0%; }
  .portfolio-overview li .info {
    min-height: 1em; }
  /**
	 * Customerpanel
	 */
  #customerpanel-content table.grid.domainnames tr td.expire-date,
  #customerpanel-content table.grid.domainnames tr th.expire-date {
    display: none; } }

/* EXTRA EXTRA SMALL */
@media (max-width: 24em) {
  header#page-header #logo {
    width: 17.53em; }
  header#page-header #logo img {
    width: 3.85em;
    margin-right: 1.05em; }
  header#page-header #logo .site-name {
    font-size: 2.7em; }
  header#page-header #logo .site-slogan {
    font-size: 0.95em; } }

/* MEDIUM */
@media (min-width: 56.9em) {
  html {
    background: #e9e9e9; }
  #page-container {
    background: #ffffff;
    padding: 0;
    max-width: 56em; }
  .mobile-menu {
    display: none; }
  .main-content {
    float: left;
    width: 33em;
    padding: 2em; }
  .one-column .main-content {
    width: 52em;
    max-width: 52em; }
  .sidebar {
    float: right;
    width: 16em;
    /* 14em + (2 * 1em padding)*/
    padding: 2em;
    padding-bottom: 0;
    padding-left: 1em; }
  .sidebar.second {
    padding-top: 0;
    clear: right; }
  .sidebar .sidebar-image {
    margin-bottom: 2em;
    width: 100%; }
  .sidebar .button {
    width: 16em;
    box-sizing: border-box;
    margin-bottom: 2em; }
  .block.double, table.double {
    width: 34.1em; }
  .block.double > div {
    width: 34.1em; }
  .block.full-width > div.block-footer, .block.double > div.block-footer {
    width: 32.1em; }
  nav#top-navigation {
    float: right;
    padding-left: 2em;
    padding-right: 2em;
    font-size: 0.875em; }
  nav#top-navigation ul li {
    float: left;
    margin-left: 1.25em; }
  nav#main-nav {
    clear: both;
    padding-left: 2em;
    padding-right: 2em; }
  nav#main-nav ul li {
    float: left;
    height: 2.5em;
    margin-right: 1.25em; }
  nav#main-nav ul li.active {
    background: url(../../images/main-nav-arrow.svg) no-repeat center bottom;
    background-size: 1em; }
  .no-svg nav#main-nav ul li.active {
    background: url(../../images/main-nav-arrow.png) no-repeat center bottom;
    background-size: 1em; }
  nav#main-nav ul li a {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.125em; }
  nav#main-nav ul li a:hover {
    text-decoration: underline; }
  /* Dropdown */
  nav#main-nav ul ul:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 0.625em 0.625em;
    border-color: #FFFFFF transparent;
    display: block;
    width: 0;
    z-index: 1;
    top: -0.625em;
    left: 2em; }
  nav#main-nav ul ul:before {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 0 0.625em 0.625em;
    border-color: #515151 transparent;
    display: block;
    width: 0;
    z-index: 0;
    top: -0.6875em;
    left: 2em; }
  nav#main-nav ul ul {
    display: none;
    position: absolute;
    background: #FFFFFF;
    border: #515151 1px solid;
    margin-top: 1em; }
  nav#main-nav ul ul li {
    float: none;
    margin: 0px;
    margin-left: 0.625em;
    margin-right: 0.625em;
    padding: 0.625em;
    padding-right: 1em;
    padding-left: 1em;
    height: auto;
    border-top: #515151 1px solid; }
  nav#main-nav ul ul li.active {
    background: url(../../images/sub-nav-arrow.svg) no-repeat left center;
    background-size: 0.625em; }
  .no-svg nav#main-nav ul ul li.active {
    background: url(../../images/sub-nav-arrow.png) no-repeat left center;
    background-size: 0.625em; }
  nav#main-nav ul ul li a {
    text-transform: none;
    font-size: 0.875em; }
  nav#main-nav ul ul li.first {
    border: none; }
  nav#main-nav ul li:hover ul {
    display: block; }
  nav.breadcrumbs {
    float: left;
    clear: both;
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
    padding-top: 1em;
    padding-bottom: 0.625em;
    border-bottom: 1px solid #e7e7e7; }
  nav.breadcrumbs ol {
    margin: 0px;
    list-style-type: none; }
  nav.breadcrumbs li {
    float: left;
    margin-right: 1em; }
  nav.breadcrumbs li:before {
    content: " > ";
    color: #d1d1d1;
    margin-right: 0.625em; }
  nav.breadcrumbs li:first-child:before {
    content: "";
    margin: 0; }
  main div#content-header {
    float: left;
    background: url(../../images/banner-background.png) no-repeat left center #ff8a00;
    width: 100%;
    color: #FFFFFF;
    overflow: hidden; }
  main div#content-header div#content-header-content {
    padding: 2em; }
  main div#content-header div#content-header-content p {
    margin: 0; }
  main div#content-header div#content-header-content q {
    background: url("../../images/quote-close.svg") no-repeat right bottom, url("../../images/quote.svg") no-repeat left top;
    background-size: 1.25em;
    padding: 0;
    padding-left: 2em;
    padding-right: 2em;
    font-size: 1.25em;
    display: inline;
    border: none; }
  .no-svg main div#content-header div#content-header-content q {
    background: url("../../images/quote-close.png") no-repeat right bottom, url("../../images/quote.png") no-repeat left top; }
  body.front main div#content-header {
    float: left;
    background: url("../../images/banner-home-right-bottom.jpg") no-repeat right bottom, url("../../images/banner-home-left-top.jpg") no-repeat left top #ff8a00;
    width: 100%; }
  /**
	 * FOOTER
	 */
  #footer-menu a {
    display: none; }
  footer div#partner-logos {
    float: left;
    width: 94%;
    margin-top: 1.25em;
    border-top: 1px solid #e7e7e7;
    padding-top: 1.25em;
    padding-bottom: 1.25em;
    margin-left: 3%;
    margin-right: 3%;
    text-align: center; }
  footer div#partner-logos img {
    height: 3.125em; }
  footer div#partner-logos img.first {
    float: left; }
  footer div#partner-logos img.center {
    margin-right: 10%; }
  footer div#partner-logos img.last {
    float: right; }
  footer div.footer-links {
    float: left;
    color: #FFFFFF;
    background: #ff8a00;
    width: 94%;
    padding: 3%;
    padding-top: 1.25em;
    padding-bottom: 1.25em; }
  footer div.footer-links a {
    color: #FFFFFF; }
  footer div.footer-links div.footer-group {
    width: 24%;
    float: left;
    margin-right: 2.5em; }
  footer div.footer-links div.footer-group h6 {
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0.5em; }
  footer div.footer-links ul {
    border-top: #ffe9cf 1px solid;
    padding-top: 0.3125em;
    width: 100%; }
  footer div.footer-links ul li {
    margin-top: 0.3125em;
    font-size: 0.875em; }
  footer span.copyright {
    float: right;
    font-size: 0.875em; }
  /* Portfolio */
  .portfolio-overview li {
    width: 30.6%;
    margin-right: 4.1%; }
  .portfolio-overview li:nth-child(3n+0) {
    margin-right: 0; }
  .max-two-columns .portfolio-overview li {
    width: 47.5%;
    margin-right: 5%; }
  .max-two-columns .portfolio-overview li:nth-child(2n+0) {
    margin-right: 0; }
  /** Contact */
  body.contact .info-container {
    float: left;
    width: 40%; }
  body.contact .main-content form {
    width: 54%;
    float: left;
    margin-right: 6%; }
  /* Website pakketten */
  body.website-pakketten div.tekst.first {
    float: right;
    width: 70%; }
  body.website-pakketten img.website-from {
    width: 25%;
    margin: 0;
    float: left; }
  /* Statistieken pakketten */
  body.statistieken-pakketten div.tekst.first {
    float: right;
    width: 70%; }
  body.statistieken-pakketten img.statistieken {
    width: 25%;
    margin: 0;
    float: left; }
  table.compare td p {
    display: block; }
  table.compare img {
    margin: 0; }
  table.compare .title-description h4, table.compare .title-description p {
    margin: 0; }
  table.compare .title-description h4 {
    margin-bottom: 0.5em; }
  /** Algemene voorwaarden */
  .main-content .first-half, .main-content .second-half {
    float: left;
    width: 48%; }
  .main-content .first-half {
    margin-right: 4%; }
  body.algemene-voorwaarden .main-content .first-half, body.algemene-voorwaarden .main-content .second-half,
  body.aanvullende-voorwaarden .main-content .first-half, body.algemene-voorwaarden .main-content .second-half {
    font-size: 0.9em; }
  /**
	 *
	 */
  body.beeldmateriaal-promotiemiddelen div.product-container {
    width: 30%;
    float: left;
    margin-right: 3%; }
  /**
	 * Product overview
	 */
  .main-content div.product h3 {
    min-height: 2.2em; }
  .main-content div.product p {
    min-height: 12.5em; }
  .main-content div.product ul {
    min-height: 19.5em; }
  /**
	 * Homepage
	 */
  body.front .portfolio-overview li:nth-child(4n+0) {
    display: none; }
  .block.full-width, table.full-width {
    width: 34.1em; }
  .block.full-width > div {
    width: 34.1em; }
  /**
	 * Customerpanel
	 */
  #customerpanelmenu {
    width: 15.5em; }
  #customerpanel-content {
    width: 36.5em; }
  #customerpanel-content .block-container {
    float: left;
    width: 16.125em;
    margin-right: 2em; }
  #customerpanel-content .block {
    margin-left: 2em; }
  #customerpanel-content table.grid {
    margin-left: 2em; }
  div.bigbanner a.full-screen-slide {
    width: 52em;
    height: 11.6em; }
  div.bigbanner a.full-screen-slide.banner-magento-hosting {
    background: url(../../images/hosting-banner-medium.svg);
    background-size: 52em auto; }
  div.bigbanner a.full-screen-slide.banner-magento-security {
    background: url(../../images/security-banner-medium.svg);
    background-size: 52em auto; }
  div.bigbanner a.full-screen-slide.banner-blog {
    background: url(../../images/tutorial-banner-medium.svg);
    background-size: 52em auto; }
  body.magento-hosting .main-content .first-half,
  body.magento-hosting .main-content .second-half {
    width: 100%; }
  body.magento-hosting .main-content .first-half {
    margin-right: 0; } }

/* LARGE */
@media (min-width: 75.9em) {
  #page-container {
    padding: 0;
    max-width: 75em; }
  .max-three-columns .main-content {
    width: 33em;
    padding: 2em; }
  .max-two-columns .main-content {
    float: left;
    width: 52em;
    max-width: 52em;
    padding: 2em; }
  .one-column .main-content {
    float: left;
    width: 71em;
    max-width: 71em;
    padding: 2em; }
  .sidebar.first {
    float: left;
    padding-left: 2em;
    padding-right: 1em; }
  .max-two-columns .sidebar.first {
    padding-left: 1em;
    padding-right: 2em; }
  .sidebar.second {
    padding-top: 2em; }
  #customerpanel-content {
    float: left;
    width: 55em; }
  .block.full-width, table.full-width {
    width: 52.3em; }
  .block.full-width > div {
    width: 52.3em; }
  .block.full-width > div.block-footer {
    width: 50.3em; }
  /* Portfolio */
  .portfolio-overview li, .portfolio-overview li:nth-child(3n+0) {
    width: 22.75%;
    margin-right: 3%; }
  .portfolio-overview li:nth-child(4n+0) {
    margin-right: 0; }
  .max-two-columns .portfolio-overview li, .max-two-columns .portfolio-overview li:nth-child(2n+0) {
    width: 30.6%;
    margin-right: 4.1%; }
  .max-two-columns .portfolio-overview li:nth-child(3n+0) {
    margin-right: 0; }
  /**
	 * Product overview
	 */
  .main-content div.product h3 {
    min-height: initial; }
  .main-content div.product p {
    min-height: 9em; }
  .main-content div.product ul {
    min-height: 15.4em; }
  /**
	 * Homepage
	 */
  body.front .portfolio-overview li:nth-child(4n+0) {
    display: initial; }
  #customerpanel-content table.grid.domainnames tr td.ssl,
  #customerpanel-content table.grid.domainnames tr th.ssl,
  #customerpanel-content table.grid.hosting tr td.domainnames,
  #customerpanel-content table.grid.hosting tr th.domainnames,
  #customerpanel-content table.grid.domainnames tr td.account,
  #customerpanel-content table.grid.domainnames tr th.account {
    display: table-cell; }
  #customerpanel-content table.grid.domainnames span.big-only {
    display: inline; }
  div.bigbanner a.full-screen-slide {
    width: 71em;
    height: 15.75em; }
  div.bigbanner a.full-screen-slide.banner-magento-hosting {
    background: url(../../images/hosting-banner-big.svg);
    background-size: 71em 15.75em; }
  div.bigbanner a.full-screen-slide.banner-magento-security {
    background: url(../../images/security-banner-big.svg);
    background-size: 71em 15.75em; }
  div.bigbanner a.full-screen-slide.banner-blog {
    background: url(../../images/tutorial-banner-big.svg);
    background-size: 71em 15.75em; }
  div.compare-products {
    width: 71em;
    margin-left: -19em; }
  div.compare-4-products .product {
    width: 25%; }
  div.force-full-page {
    width: 71em;
    margin-left: -19em; }
  body.magento-hosting .main-content .first-half,
  body.magento-hosting .main-content .second-half {
    width: 48%; }
  body.magento-hosting .main-content .first-half {
    margin-right: 4%; } }

#tag_ids {
  width: 22em; }

.select2-container--default .select2-selection--multiple {
  border-radius: 0; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding: 0.3125em; }

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0; }

/*# sourceMappingURL=app.css.map */
